@import "../../../scss/variables.scss";

.wrapper {
  &_group {
    padding-bottom: 25px;
    margin-bottom: 25px;
    border-bottom: 1px solid #e3e6ec;

    &_title {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      color: $black;
      margin-bottom: 25px;
    }

    &_field {
      margin-bottom: 20px;
      width: 400px;
    }

    &_buttons {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
  }

  &_footer {
    &_description {
      margin-bottom: 25px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $text;
    }

    &_button {
      width: 220px;
    }
  }
}
