.wrapper {
  &_field {
    position: relative;
    margin-bottom: 22px;
  }

  &_program {
    position: relative;
    z-index: 99;

    &_container {
      display: flex;
      align-items: center;
      gap: 10px;

      &_item {
        flex: 1;
      }
    }
  }

  &_scheduling {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;

    &_date {
      flex: 2;
    }

    &_time {
      flex: 3;

      &_wrapper {
        display: flex;
        gap: 10px;

        &_item {
          flex: 1;
        }
      }
    }
  }

  &_buttons {
    display: flex;
    gap: 10px;

    &_item {
      flex: 1;
    }
  }
}
