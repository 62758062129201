.wrapper {
  &_field {
    position: relative;
    margin-bottom: 22px;
  }

  &_program {
    position: relative;
    margin-bottom: 22px;
    z-index: 99;
    padding-right: 15%;

    &_container {
      display: flex;
      align-items: center;
      gap: 10px;

      &_item {
        flex: 1;
      }
    }
  }

  &_buttons {
    display: flex;
    gap: 10px;

    &_item {
      flex: 1;
    }
  }
}
