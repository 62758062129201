@import "../../scss/variables.scss";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 24px;
  height: 24px;
  z-index: 1000;
  cursor: pointer;

  &_arrows {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.modal {
  position: absolute;
  right: 0;
  top: 100%;
  display: flex;
  justify-content: center;
  z-index: 1001;

  &_wrapper {
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 15px 80px 0px rgba(227, 230, 236, 0.8);
    padding: 10px;
    width: 150px;

    &_item {
      display: flex;
      align-items: center;
      padding: 8px 10px;
      font-size: 13px;
      font-weight: 700;
      line-height: 18px;
      color: $black;
      border-radius: 10px;
      transition: 0.2s;
      cursor: pointer;

      &:hover {
        background-color: #f8f8f8;
      }

      &_icon {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
      }
    }
  }
}
