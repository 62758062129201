@import "../../../../scss/variables.scss";

.wrapper {
  &_header {
    display: flex;
    justify-content: center;
    padding: 22px 0;
    border-bottom: 1px solid #e4e3e0;

    &_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      &_avatar {
        width: 100px;
        height: 100px;
        border-radius: 100px;
        overflow: hidden;
        margin-bottom: 10px;
      }

      &_text {
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        color: $darkgray;
      }
    }
  }

  &_name {
    padding-top: 22px;
    display: flex;
    gap: 10px;
    margin-bottom: 15px;

    &_item {
      flex: 1;
    }
  }

  &_field {
    margin-bottom: 15px;
  }

  &_button {
    margin-top: 22px;
    padding-bottom: 30px;
  }
}
