html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  text-align: left;
  font-family: "Eudoxus Sans";
}

textarea:focus,
input:focus {
  outline: none;
}

input,
button,
textarea {
  all: unset;
}

input {
  text-align: left;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: left;
}

a {
  text-decoration: none !important;
}

.shadow_box {
  background-color: white;
  padding: 30px;
  box-shadow: 0px 27px 72px 0px rgba(27, 27, 29, 0.06);
  border-radius: 24px;
}

a {
  text-decoration: none;
  cursor: pointer;
}

a:link,
a:visited {
  color: black;
}

a:visited {
  color: none;
}

a:hover {
  text-decoration: underline;
  color: gray !important;
}

a:focus {
  outline: 1px dotted black;
}

p {
  margin: 0;
}

.ap {
  margin-top: 0 !important;
}

/* .ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: attr(data-value) !important;
} */

span[data-value="6px"]::before {
  content: "6px" !important;
}

span[data-value="8px"]::before {
  content: "8px" !important;
}

span[data-value="10px"]::before {
  content: "10px" !important;
}

span[data-value="12px"]::before {
  content: "12px" !important;
}

span[data-value="14px"]::before {
  content: "14px" !important;
}

span[data-value="16px"]::before {
  content: "16px" !important;
}

span[data-value="18px"]::before {
  content: "18px" !important;
}

span[data-value="20px"]::before {
  content: "20px" !important;
}

span[data-value="22px"]::before {
  content: "22px" !important;
}

span[data-value="24px"]::before {
  content: "24px" !important;
}
span[data-value="26px"]::before {
  content: "26px" !important;
}
span[data-value="28px"]::before {
  content: "28px" !important;
}
span[data-value="30px"]::before {
  content: "30px" !important;
}
span[data-value="32px"]::before {
  content: "32px" !important;
}

span[data-value="32px"]::before {
  content: "32px" !important;
}
/* 
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "16px";
} */

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  max-height: 100px;
  overflow-y: auto;
}
