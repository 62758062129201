@import "../../scss/variables.scss";

.wrapper {
  padding: 20px;
  border-radius: 12px;
  background-color: white;

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 22px;

    &_title {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      color: $black;
    }

    &_icon {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  &_description {
    font-size: 18px;
    line-height: 24px;
    color: $black;
    margin-bottom: 40px;
  }

  &_buttons {
    display: flex;
    align-items: center;
    gap: 15px;

    &_item {
      flex: 1;
    }
  }
}
