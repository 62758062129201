@import "../../scss/variables.scss";

.wrapper {
  margin-bottom: 8px;
}

.label {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  color: $darkgray;
}

.subtitle {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: $darkgray;
}
