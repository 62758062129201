@import "../../scss/variables.scss";

.pagination {
  padding-top: 25px;
}

.item {
  display: flex;
  align-items: center;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $black;
  border-bottom: 1px solid $lightgray;

  & > div {
    flex: 1;
  }

  &_email {
    flex: 2 !important;
  }
}
