.wrapper {
  position: relative;
  padding: 25px 30px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 27px 72px 0px rgba(27, 27, 29, 0.18);
  width: 470px;

  &_cross {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 7px;
    right: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &_container {
    display: flex;
    align-items: center;

    &_button {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 22px;
      border-radius: 8px;
      background-color: #f2f1fb;
      cursor: pointer;
    }

    &_audio {
      flex: 1;

      &_name {
        margin-bottom: 3px;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        color: #7e7e8b;
      }

      &_progress {
        position: relative;
        overflow: hidden;
        height: 4px;
        border-radius: 3px;
        background-color: #b2c2db;
        margin-bottom: 3px;

        &_active {
          position: absolute;
          height: 100%;
          border-radius: 3px;
          width: 50%;
          background-color: #2f4872;
        }
      }

      &_footer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &_value {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          color: #142941;
        }
      }
    }
  }
}
