@import "../../scss/variables.scss";

.wrapper {
  position: relative;
  border-radius: 8px;
  border: 1px solid $mediumgray;
  z-index: 200;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 5px;
  height: 58px;
  box-sizing: border-box;

  &_title {
    display: flex;
    align-items: center;
  }

  &_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
    cursor: pointer;

    &_title {
      flex: 1;
      font-size: 14px;
      line-height: 20px;
      color: $black !important;
      margin-right: 10px;
    }

    &_placeholder {
      display: block;
      color: $gray;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $mediumgray;
      width: 100%;
    }
  }

  &_arrows {
    display: flex;
    flex-direction: column;
    padding: 5px 0;

    &_item {
      flex: 1;
      width: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &_transform {
        transform: rotate(180deg);
      }

      & path {
        stroke: $black !important;
      }
    }
  }
}

.input {
  display: block;
  box-sizing: border-box;
  min-width: 30px;
  width: 100%;
  // width: 30px;
}
