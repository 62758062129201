@import "../../scss/variables.scss";

.wrapper {
  display: flex;
  align-items: center;

  &_info {
    margin-right: 15px;

    &_label {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      color: $black;
    }

    &_sublabel {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: $darkgray;
    }
  }

  &_switcher {
    position: relative;
    width: 52px;
    height: 30px;
    background-color: $mediumgray;
    border-radius: 30px;
    cursor: pointer;
    transition: 0.2s;

    &_active {
      background-color: $primary;
    }
  }

  &_circle {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 18px;
    height: 18px;
    background-color: white;
    border-radius: 18px;
    transition: 0.2s;

    &_active {
      left: 28px;
    }
  }
}
