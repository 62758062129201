@import "../../../scss/variables.scss";

.title {
  margin-bottom: 15px;
  color: $black;
  font-size: 14px;
  font-weight: 700;
}

.wrapper {
  display: flex;
  align-items: center;
  //   gap: 15px;

  &_item {
    flex: 1;
  }

  &_separator {
    margin: 0 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $black;
  }
}
