@import "../../../scss/variables.scss";

.wrapper {
  &_header {
    display: flex;
    align-items: center;
    // margin-bottom: 28px;

    &_search {
      flex: 1;

      &_wrapper {
        width: 300px;
      }
    }

    &_button {
      margin-right: 50px;
    }
  }

  &_container {
    position: relative;
    display: flex;
  }

  &_actions {
    position: relative;
    z-index: 999;
    width: 30px;
    padding-top: 74px;
    display: flex;
    flex-direction: column;

    &_item {
      // flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      color: black;
      box-sizing: border-box;
    }
  }

  &_table {
    position: relative;
    overflow-x: scroll;
    max-height: max-content;
    z-index: 999;
    padding-top: 40px;
    flex: 1;
  }

  &_item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $black;
    border-bottom: 1px solid $lightgray;
    cursor: pointer;
    word-wrap: break-word;
    width: max-content;
    position: relative;

    & > div {
      min-width: 100px;
      max-width: 100px;
    }

    &_id {
      min-width: 106px !important;
    }

    &_source {
      min-width: 80px !important;
    }

    &_name {
    }

    &_email {
      min-width: 160px !important;
      max-width: 160px !important;
      padding-right: 5px;
      box-sizing: border-box;
    }

    &_stage {
      min-width: 130px !important;
    }

    &_dots {
      min-width: auto !important;
    }

    &_status {
      display: flex;
      justify-content: center;
    }

    &_as {
      display: flex;
      justify-content: center;
    }
  }

  &_pagination {
    padding-top: 25px;
  }

  &_loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
