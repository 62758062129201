@import "../../scss/variables.scss";

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  padding: 14px 24px;
  border-radius: 12px;
  background-color: $primary;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: white;
  box-sizing: border-box;
  cursor: pointer;

  &_transparent {
    background-color: transparent;
    color: $black;
    border: 1px solid $lightgray;
  }

  &_red {
    background-color: $red;
    color: white;
  }

  &_red_transparent {
    background-color: transparent;
    border: 1px solid $red;
    color: $red;
  }

  &_green_transparent {
    background-color: transparent;
    border: 1px solid $green;
    color: $green;
  }

  &_disable {
    color: $black;
    background-color: $background;
  }
}

.icon {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
