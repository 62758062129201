@import "../../../scss/variables.scss";

.wrapper {
  padding: 25px 0;
  background-color: white;
  border-radius: 24px;
  min-width: 300px;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px;

    &_title {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      color: $black;
    }

    &_clear {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      color: $primary;
      cursor: pointer;
    }
  }

  &_content {
    padding: 20px 25px;
    // max-height: 400px;
    // overflow-y: auto;
  }

  &_button {
    padding: 0 25px;
  }
}
