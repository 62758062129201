.ql-toolbar {
  border: none !important;
  border-bottom: 1px solid #e4e4e4 !important;
}

.quill {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.ql-container {
  min-height: 10rem;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  border: none !important;
  font-size: 16px;
}

.ql-editor {
  height: 100%;
  max-height: 100%;
  flex: 1;
  overflow-y: auto;
  width: 100%;
  font-size: 16px;
}
