@import "../../scss/variables.scss";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  &_left {
    display: flex;
    align-items: center;
    cursor: pointer;

    &_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      margin-right: 10px;
    }

    &_title {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      color: $black;
    }
  }

  &_right {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
