@import "../../scss/variables.scss";

.wrapper {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: $green;

  &_declined {
    background-color: $mediumgray;
  }

  & path {
    stroke: white;
  }
}
