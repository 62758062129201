.wrapper {
  position: relative;
  height: 100%;
  width: 100%;

  &_image {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &_skeletone {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100%;
  }
}
