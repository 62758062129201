@import "../../../scss/variables.scss";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_left {
    display: flex;
    align-items: center;
    flex: 1;
    padding-right: 5px;

    &_icon {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $additional;
      border-radius: 8px;
      margin-right: 15px;
      cursor: pointer;
    }

    &_info {
      &_title {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        color: $black;
      }

      &_size {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: $darkgray;
      }
    }
  }

  &_delete {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    & path {
      fill: $mediumgray !important;
    }
  }
}
