@import "../../scss/variables.scss";

.wrapper {
  display: flex;
  gap: 5px;

  &_button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    padding: 14px 10px;
    height: 48px;
    min-width: 48px;
    // background-color: rgba(228, 228, 228, 0.5);
    border: 1px solid #e4e3e0;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: $black;
    box-sizing: border-box;
    cursor: pointer;

    &_active {
      background-color: $primary;
      border: none;
      color: white;
    }

    &_transparent {
      background-color: transparent;
      padding: 14px 5px;
      min-width: 20px;
      border: none;
      cursor: default;
    }
  }
}
