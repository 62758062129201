@import "../../../../scss/variables.scss";

.profile {
  position: relative;
  display: flex;
  align-items: center;
  padding: 13px 20px;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    background-color: $neutralgray;
  }

  &_info {
    flex: 1;
    margin: 0 10px;

    &_name {
      font-size: 14px;
      line-height: 20px;
      color: $black;
      font-weight: 700;
    }
    &_role {
      font-size: 13px;
      font-weight: 700;
      line-height: 18px;
      color: $darkgray;
    }
  }
}

.modal {
  position: absolute;
  left: 0;
  bottom: 95%;
  width: 100%;
  display: flex;
  justify-content: center;

  &_wrapper {
    width: 80%;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 15px 80px 0px rgba(227, 230, 236, 0.8);
    padding: 10px;

    &_item {
      display: flex;
      align-items: center;
      padding: 8px 10px;
      font-size: 13px;
      font-weight: 700;
      line-height: 18px;
      color: $black;
      border-radius: 10px;
      transition: 0.2s;

      &:hover {
        background-color: #f8f8f8;
      }

      &_icon {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
      }
    }
  }
}
