.wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 167px;

  & img {
    display: block;
    object-fit: cover;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    max-height: 167px;
    border-radius: 5px;
  }

  &_delete {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(9, 9, 20, 0.4);
    border-radius: 36px;
    cursor: pointer;

    & path {
      fill: white !important;
    }
  }

  &_filename {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.748358718487395) 0%,
      rgba(0, 0, 0, 0.056481967787114895) 100%
    );
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-size: 14px;
    color: white;
    box-sizing: border-box;
  }
}
