@import "../../scss/variables.scss";

.textarea {
  display: block;
  height: 96px;
  width: 100%;
  border: 1px solid $mediumgray;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $black;
  padding: 14px 23px;
  text-align: left;
  box-sizing: border-box;
  resize: none;

  &::placeholder {
    color: $mediumgray;
  }

  &:focus {
    background-color: transparent;
    border-color: $black;
  }
}
