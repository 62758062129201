@import "../../scss/variables.scss";

.wrapper {
  position: relative;
  border-radius: 8px;
  border: 1px solid $mediumgray;
  z-index: 200;

  &_disable {
    opacity: 0.5;
  }

  &_error {
    border: 1px solid $red;
  }

  &_label {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    color: $darkgray;
    cursor: pointer;
  }

  &_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 20px;
    cursor: pointer;

    &_title {
      flex: 1;
      font-size: 14px;
      line-height: 20px;
      color: $black !important;
      margin-right: 10px;
    }

    &_placeholder {
      color: $gray;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $mediumgray;
    }

    &_icon {
      stroke: black;
    }
  }

  &_options {
    position: absolute;
    left: 0;
    // top: 100%;
    width: 100%;
    max-height: 350px;
    padding: 5px 0;
    background: white;
    overflow-y: auto;
    border-radius: 16px;
    transform: translateY(5px);
    box-shadow: -5px 5px 25px 5px rgba(227, 230, 236, 0.5);

    &_item {
      padding: 10px;

      &:hover {
        background: rgb(247, 247, 247);
        cursor: pointer;
      }

      &_selected {
        background: rgb(247, 247, 247);
      }
    }
  }
}
