@import "../../scss/variables.scss";

.loading {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;

    &_info {
      &_title {
        font-size: 32px;
        font-weight: 700;
        color: $black;
      }

      &_description {
        margin-top: 5px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: $text;
      }
    }

    &_item {
      display: flex;
      align-items: center;

      &_icon {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;

        & path {
          fill: $mediumgray !important;
        }
      }

      &_info {
        &_value {
          font-size: 18px;
          font-weight: 700;
          line-height: 24px;
          color: $black;
        }

        &_label {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          color: $text;
        }
      }
    }
  }

  &_info {
    display: flex;
    gap: 10px;
    margin-bottom: 25px;

    &_item {
      flex: 1;

      &_label {
        margin-bottom: 2px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: $darkgray;
      }

      &_value {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: $black;
      }

      &_status {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        background-color: green;

        &_declined {
          background-color: #ff0033;
        }

        & path {
          stroke: white;
        }
      }
    }
  }

  &_tabs {
    margin-bottom: 25px;
  }

  &_text {
    word-wrap: break-word;
    padding: 0 !important;

    & blockquote {
      border-left: 4px solid #ccc;
      margin-bottom: 5px;
      margin-top: 5px;
      padding-left: 16px;
    }

    & p {
      font-size: 16px;

      & strong {
        font-weight: 700;
      }

      & em {
        font-style: italic;
      }
    }
  }

  &_table {
    &_header {
      display: flex;
      align-items: center;
      margin-bottom: 28px;

      &_search {
        flex: 1;

        &_wrapper {
          width: 300px;
        }
      }

      &_button {
        margin-right: 50px;
      }
    }
  }
}
