@import "../../../scss/variables.scss";

.wrapper {
  &_header {
    display: flex;
    align-items: center;
    margin-bottom: 28px;

    &_search {
      flex: 1;

      &_wrapper {
        width: 300px;
      }
    }
  }

  &_item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $black;
    border-bottom: 1px solid $lightgray;
    cursor: pointer;

    & > div {
      flex: 1;
    }

    &_user {
      display: flex;
      align-items: center;

      &_avatar {
        margin-right: 15px;
        width: 44px;
        height: 44px;
        border-radius: 44px;
        overflow: hidden;
      }

      &_name {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: $black;
      }
    }

    &_email {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $black;
    }

    &_role {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: $black;
    }

    &_dots {
      flex: none !important;
      position: relative;
    }
  }

  &_pagination {
    padding-top: 25px;
  }
}
