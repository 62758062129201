@import "../../scss/variables.scss";

.wrapper {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid $lightgray;

  &_checkbox {
    margin-right: 15px;
  }

  &_item {
    display: flex;
    align-items: center;
    cursor: pointer;

    &_title {
      position: relative;
      font-size: 13px;
      line-height: 18px;
      font-weight: 700;
      margin-right: 3px;
      color: $mediumgray;

      &_active {
        color: $black;

        .wrapper_item_arrow path {
          stroke: $black !important;
          fill: $black !important;
        }
      }

      &:hover {
        & .wrapper_item_popup {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &_popup {
      position: absolute;
      bottom: 100%;
      left: 50%;
      padding-bottom: 5px;
      transform: translateX(-50%);
      opacity: 0;
      visibility: hidden;
      transition: 0.1s;

      &_wrapper {
        position: relative;
        padding: 5px 14px;
        background-color: #1b1d21;
        border-radius: 6px;
        font-size: 13px;
        font-weight: 700;
        line-height: 18px;
        color: white;
        white-space: nowrap;

        &_arrow {
          height: 4px;
          width: 8px;
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &_arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 18px;
      height: 18px;
    }
  }
}
