@import "../../../scss/variables.scss";

.wrapper {
  &_header {
    display: flex;
    align-items: center;
    margin-bottom: 28px;

    &_search {
      flex: 1;

      &_wrapper {
        width: 300px;
      }
    }

    &_button {
      margin-right: 50px;
    }
  }

  &_item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $black;
    border-bottom: 1px solid $lightgray;
    cursor: pointer;

    & > div {
      flex: 1;
    }

    &_checkbox {
      flex: none !important;
      margin-right: 15px;
    }

    &_dots {
      flex: none !important;
      position: relative;
    }

    &_info {
      flex: 2 !important;
      word-wrap: break-word;
      overflow: hidden;

      &_name {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: $black;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
        padding-right: 10px;
      }
    }

    &_note {
      flex: 2 !important;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $text;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* Limit to 2 lines */
      -webkit-box-orient: vertical;
      overflow: hidden;
      white-space: normal; /* Allow wrapping within lines */
      text-overflow: ellipsis;
    }

    &_status {
      display: flex;
      justify-content: center;

      &_item {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        background-color: green;

        &_declined {
          background-color: #ff0033;
        }

        & path {
          stroke: white;
        }
      }
    }
  }

  &_pagination {
    padding-top: 25px;
  }
}
