.wrapper {
  &_field {
    position: relative;
    margin-bottom: 22px;
  }

  &_buttons {
    display: flex;
    gap: 10px;

    &_item {
      flex: 1;
    }
  }
}
