@import "../../../scss/variables.scss";

.wrapper {
  position: relative;
  z-index: 1000;

  &_filter {
    position: absolute;
    top: 100%;
    right: 0;
    padding-top: 10px;
    width: 400px;

    &_item {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid $neutralgray;

      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
      }
    }
  }
}
