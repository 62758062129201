@import "../../scss/variables.scss";

.container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid $mediumgray;

  &_checked {
    background-color: $primary;
    border: 1px solid $primary;
  }
}

.label {
  margin-left: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $neutraldark;
}
