@import "../../scss/variables.scss";

.editor {
  border-radius: 8px;
  border: 1px solid $mediumgray;

  & strong {
    font-weight: 700 !important;
  }

  & em {
    font-style: italic !important;
  }
}
