@import "../../../../scss/variables.scss";

.wrapper {
  &_table {
    &_header {
      display: flex;
      align-items: center;
      margin-bottom: 28px;

      &_search {
        flex: 1;

        &_wrapper {
          width: 300px;
        }
      }

      &_button {
        margin-right: 50px;
      }
    }
  }
}

.item {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid $lightgray;

  & > div {
    flex: 1;
    font-size: 14px;
    line-height: 20px;
    color: $black;
  }

  &_title {
    flex: 3 !important;
  }

  &_play {
    flex: none !important;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.pagination {
  padding-top: 25px;
}
