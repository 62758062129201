@import "../../scss/variables.scss";

.loading {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  &_name {
    margin-bottom: 25px;
    font-size: 32px;
    font-weight: 700;
    color: $black;
  }

  &_tabs {
    margin-bottom: 25px;
  }

  &_info {
    display: flex;
    flex-wrap: wrap;

    &_item {
      flex-basis: 25%;
      margin-bottom: 25px;

      &_title {
        font-size: 14px;
        line-height: 20px;
        color: $darkgray;
        margin-bottom: 2px;
      }

      &_value {
        font-size: 16px;
        line-height: 24px;
        color: $black;
      }
    }
  }
}
