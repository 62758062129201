.wrapper {
  &_scheduling {
    display: flex;
    gap: 10px;

    &_date {
      flex: 2;
    }

    &_time {
      flex: 3;

      &_wrapper {
        display: flex;
        gap: 10px;

        &_item {
          flex: 1;
        }
      }
    }
  }

  &_checkbox {
    margin: 15px 0;
    display: flex;
  }
}
