@import "../../../scss/variables.scss";

.wrapper {
  display: flex;
  height: 100vh;

  &_menu {
    width: 260px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    border-right: 1px solid $gray;

    &_logo {
      padding: 40px 0;
      padding-left: 36px;
      font-size: 32px;
      font-weight: 700;
      color: $primary;
    }

    &_nav {
      padding: 0 20px;
      flex: 1;

      &_item {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        padding: 16px 20px;
        border-radius: 12px;
        color: $primary;
        transition: 0.1s;
        cursor: pointer;

        &:hover {
          background-color: $primary;

          .wrapper_menu_nav_item_text {
            color: white !important;
          }

          .wrapper_menu_nav_item_icon {
            path,
            rect,
            svg,
            circle {
              fill: white !important;
              //   stroke: white !important;
            }
          }
        }

        &_active {
          background-color: $primary;
          color: white !important;

          path,
          rect,
          svg,
          circle {
            fill: white !important;
          }
        }

        &_icon {
          margin-right: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &_text {
          font-size: 14px;
          line-height: 20px;
          font-weight: 700;
          transition: 0.1s;
        }
      }
    }

    &_profile {
      display: flex;
      align-items: center;
      padding: 30px 25px;
      transition: 0.2s;
      cursor: pointer;

      &:hover {
        background-color: $neutralgray;
      }

      &_info {
        flex: 1;
        margin: 0 10px;

        &_name {
          font-size: 14px;
          line-height: 20px;
          color: $black;
          font-weight: 700;
        }
        &_description {
          font-size: 13px;
          line-height: 18px;
          color: $lightgray;
          font-weight: 700;
        }
      }
    }
  }

  &_content {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 40px 50px;
    box-sizing: border-box;
    overflow: auto;
  }
}

.audio_wrapper {
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5000;
}
