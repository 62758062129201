@import "../../scss/variables.scss";

.wrapper {
  position: relative;
  border-radius: 8px;
  border: 1px solid $mediumgray;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  cursor: pointer;

  &_disable {
    opacity: 0.5;
  }

  &_label {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    color: $darkgray;
    cursor: pointer;
  }

  &_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 20px;
    cursor: pointer;

    &_title {
      flex: 1;
      font-size: 14px;
      line-height: 20px;
      color: $black !important;
      margin-right: 10px;
    }

    &_placeholder {
      color: $gray;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $mediumgray;
    }
  }
}

.container {
  position: relative;

  &_label {
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: $label;
  }
}

.calendar {
  position: absolute;
  top: 100%;
  padding-top: 10px;
  z-index: 2000;
}
