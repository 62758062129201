@import "../../scss/variables.scss";

.wrapper {
  border-radius: 12px;
  background-color: white;
  display: flex;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 22px;

    &_title {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      color: $black;
    }

    &_cross {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}
