$lightgray: #e6e6e9;
$neutralgray: #e4e4e4;
$black: #090914;
$red: #f03;
$main: #54585f;
$label: #b2b3bd;
$mediumgray: #a8a8bb;
$darkgray: #7e7e8b;
$light: #f8f8f8;

$primary: #142941;
$neutraldark: #1b1d21;
$background: #f0f0f4;
$text: #595961;
$gray: #c7ced9;
$light: #808191;
$additional: #f2f1fb;
$green: #16c350;
