@import "../../../scss/variables.scss";

.wrapper {
  padding: 24px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 27px 72px 0px rgba(27, 27, 29, 0.18);
  width: 308px;

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 17px;
    border-bottom: 1px solid #e4e5e7;
    margin-bottom: 17px;

    &_arrow {
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &_rotate {
        transform: rotate(180deg);
      }
    }

    &_title {
      flex: 1;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      color: $primary;
      text-align: center;
    }
  }

  &_days {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    &_item {
      flex: 1;
      text-align: center;
      color: #7e818c;
      text-align: center;
      font-size: 10px;
      font-weight: 500;
      margin-top: 0;
    }
  }

  &_month {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    &_item {
      margin-bottom: 16px;
      flex-basis: 14.28%;
      display: flex;
      justify-content: center;

      &_wrapper {
        width: 24px;
        height: 24px;
        border-radius: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        transition: 0.2s;
        color: $black;
        cursor: pointer;

        &:hover {
          background-color: $primary;
          color: white;
        }

        &_active {
          background-color: $primary;
          color: white;
        }

        &_disable {
          opacity: 0.3;

          &:hover {
            color: $black;
            background-color: transparent !important;
          }
        }
      }
    }
  }
}
