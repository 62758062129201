.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 48px;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.09);
  cursor: pointer;
}
