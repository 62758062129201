@import "../../scss/variables.scss";

.wrapper {
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 16px;
  background-color: $background;

  &_input {
    display: block;
    flex: 1;
    height: 56px;
    padding-left: 16px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    color: $black;
    background-color: transparent;
    border: none;

    &:focus::placeholder {
      color: transparent;
    }

    &::placeholder {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $darkgray;
    }
  }
}
