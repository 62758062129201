@import "../../scss/variables.scss";

.wrapper {
  display: flex;

  &_item {
    padding: 8px 18px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      background-color: $primary;
      color: white;

      .wrapper_item_title {
        color: white;
      }

      .wrapper_item_count {
        color: white;
        background-color: white;
        color: $black;
      }
    }

    &_active {
      background-color: $primary;
    }

    &_title {
      color: $darkgray;
      font-size: 14px;
      line-height: 20px;
      font-weight: 700;

      &_active {
        color: white;
      }
    }

    &_count {
      height: 20px;
      min-width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 8px;
      padding: 0 6px;
      border-radius: 20px;

      background-color: $lightgray;
      color: $darkgray;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;

      &_active {
        background-color: white;
        color: $black;
      }
    }
  }
}
