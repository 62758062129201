@import "../../scss/variables.scss";

.wrapper {
  &_field {
    position: relative;
    margin-bottom: 22px;
  }

  &_program {
    position: relative;
    margin-bottom: 22px;
    z-index: 99;

    &_container {
      display: flex;
      align-items: center;
      gap: 10px;

      &_item {
        flex: 1;
      }
    }
  }

  &_file {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px dashed $gray;
    border-radius: 5px;
    cursor: pointer;

    &_icon {
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      background-color: $additional;
      border-radius: 60px;
    }

    &_text {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $light;

      &_underline {
        text-decoration: underline;
        color: $primary;
      }
    }
  }

  &_buttons {
    display: flex;
    gap: 10px;

    &_item {
      flex: 1;
    }
  }
}
