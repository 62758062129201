@import "../../../../scss/variables.scss";

.wrapper {
  &_info {
    display: flex;
    flex-wrap: wrap;

    &_item {
      flex-basis: 25%;
      margin-bottom: 25px;

      &_title {
        font-size: 14px;
        line-height: 20px;
        color: $darkgray;
        margin-bottom: 2px;
      }

      &_value {
        font-size: 16px;
        line-height: 24px;
        color: $black;
      }
    }
  }
}
