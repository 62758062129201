@import "../../../scss/variables.scss";

.wrapper {
  border-radius: 12px;
  background-color: white;

  &_title {
    margin-bottom: 22px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    color: $black;
  }

  &_input {
    margin-bottom: 22px;
  }

  &_buttons {
    display: flex;
    gap: 10px;

    &_item {
      flex: 1;
    }
  }
}
