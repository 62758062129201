@import "../../scss/variables.scss";

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  &_description {
    color: $text;
    font-size: 16px;
    line-height: 24px;
  }

  &_item {
    margin: 25px 0;
  }

  &_buttons {
    flex: 1;
    align-items: flex-end;
    display: flex;
    gap: 10px;

    &_item {
      flex: 1;
    }
  }
}
