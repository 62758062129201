@import "../../scss/variables.scss";

.wrapper {
  &_label {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    color: $darkgray;
  }

  &_input {
    display: block;
    height: 56px;
    width: 100%;
    border: 1px solid $mediumgray;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $black;
    padding: 0 23px;
    text-align: left;
    box-sizing: border-box;

    &::placeholder {
      color: $mediumgray;
    }

    &_hide {
      padding-right: 60px;
    }

    &_focused {
      background-color: transparent;
      border-color: $black;
    }

    &_wrapper {
      position: relative;
    }

    &_error {
      border: 1px solid $red;
    }

    &_secure {
      display: flex;
      align-items: center;
      position: absolute;
      right: 23px;
      top: 0;
      height: 100%;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: $label;
      cursor: pointer;
    }
  }

  &_error {
    color: $red;
    font-size: 12px;
    line-height: 16px;
    margin-top: 8px;
  }
}
